<app-loader [visible]="loading"></app-loader>

<ng-template #mobile>
  <div *ngIf="!loading && order" class="mobile-container">
    <app-header-mobile
      [showBackButton]="orderDetailsExpanded"
      (collapseOrderDetailsEvent)="collapseOrderDetails($event)"
    ></app-header-mobile>
    <app-main-content-mobile [order]="order"></app-main-content-mobile>
    <app-order-details-mobile
      [order]="order"
      [orderId]="orderId || ''"
      [expanded]="orderDetailsExpanded"
      (showMoreInfoEvent)="expandOrderDetails($event)">
    </app-order-details-mobile>
  </div>
</ng-template>

<ng-template #desktop>
  <div *ngIf="!loading && order" class="desktop-container">
    <app-side-component [order]="order"></app-side-component>
    <app-order-details [order]='order' [orderId]='orderId || "" '></app-order-details>
  </div>
</ng-template>
<ng-container *ngTemplateOutlet="isMobileScreenWidth ? mobile : desktop"></ng-container>
