import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss']
})
export class HeaderMobileComponent {
  @Input() showBackButton: boolean = false;

  @Output() collapseOrderDetailsEvent = new EventEmitter()
}
