import { Component, OnDestroy, OnInit } from "@angular/core";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { OrderService } from "src/app/services/order.service";
import { Subscription } from "rxjs";
import { Order } from "src/app/models/order";
import { ActivatedRoute, Router } from "@angular/router";

const ORDER_ID_PATH_PARAM = "id";
const TOKEN_QUERY_PARAM = "token";

@Component({
  selector: "app-homepage",
  templateUrl: "./homepage.component.html",
  styleUrls: ["./homepage.component.scss"]
})
export class HomepageComponent implements OnInit, OnDestroy {
  mobileMaxWidth: number = 500;
  isMobileScreenWidth: boolean = false;

  orderId: string | null = '';
  token: string | null = null;

  loading = false;
  order?: Order;

  $orderDataSub: Subscription = new Subscription();

  orderDetailsExpanded: boolean = false;

  constructor(public breakpointObserver: BreakpointObserver,
              private orderService: OrderService,
              private router: Router,
              private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe([`(min-width: ${this.mobileMaxWidth}px)`])
      .subscribe((state: BreakpointState) => {
        this.isMobileScreenWidth = !state.matches;
      });

    this.orderId = this.route.snapshot.paramMap.get(ORDER_ID_PATH_PARAM);
    this.token = this.route.snapshot.queryParamMap.get(TOKEN_QUERY_PARAM);

    if (!this.orderId || !this.token) {
      this.router.navigate(["/expired"])
      return;
    }

    this.$orderDataSub = this.orderService.getOrder(this.orderId, this.token).subscribe(
      (order: Order) => {
        this.loading = false;
        this.order = order;
      },
      error => {
        if (error.status === 401)
          this.router.navigate(["/expired"])
        // todo - handle errors
      }
    );
  }

  expandOrderDetails(event: any) {
    this.orderDetailsExpanded = true;
  }

  collapseOrderDetails(event: any) {
    this.orderDetailsExpanded = false;
  }

  ngOnDestroy() {
    this.$orderDataSub.unsubscribe();
  }

}
