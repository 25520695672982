<div class="container" [ngSwitch]="order.status">
  <ng-template [ngSwitchCase]="OrderStatus.UNASSIGNED">
    <img class="cover-image" src="assets/img/illustrations/Received.svg" />
  </ng-template>

  <ng-template [ngSwitchCase]="OrderStatus.UPCOMING">
    <img class="cover-image" src="assets/img/illustrations/Courier%20assigned.svg" />
  </ng-template>

  <ng-template [ngSwitchCase]="OrderStatus.WAITING_FOR_PICKUP">
    <app-map
      [courierCoordinates]="order.courier?.coordinates"
      [pickupCoordinates]="order.pickupAddress.coordinates"
      [dropoffCoordinates]="order.dropoffAddress.coordinates">
    </app-map>
  </ng-template>

  <ng-template [ngSwitchCase]="OrderStatus.PICKED_UP">
    <img class="cover-image" src="assets/img/illustrations/Picked%20up.svg" />
  </ng-template>

  <ng-template [ngSwitchCase]="OrderStatus.WAITING_FOR_DROP_OFF">
    <app-map
      [courierCoordinates]="order.courier?.coordinates"
      [pickupCoordinates]="order.pickupAddress.coordinates"
      [dropoffCoordinates]="order.dropoffAddress.coordinates">
    </app-map>
  </ng-template>

  <ng-template [ngSwitchCase]="OrderStatus.DROPPED_OFF">
    <img class="cover-image" src="assets/img/illustrations/Dropped%20off.svg" />
  </ng-template>
</div>



