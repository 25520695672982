import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Order } from "src/app/models/order";

@Component({
  selector: 'app-order-details-mobile',
  templateUrl: './order-details-mobile.component.html',
  styleUrls: ['./order-details-mobile.component.scss']
})
export class OrderDetailsMobileComponent{
  @Input() order!: Order;
  @Input() orderId!: string;
  @Input() expanded: boolean = false;
  @Output() showMoreInfoEvent = new EventEmitter();
}
