import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LayoutModule } from '@angular/cdk/layout';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { environment } from "src/environments/environment";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { ButtonComponent } from './components/button/button.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { DeliveryAddressComponent } from './components/delivery-address/delivery-address.component';
import { OrderDetailsComponent } from './components/order-details/order-details.component';
import { HeaderMobileComponent } from "./components/header-mobile/header-mobile.component";
import { OrderDetailsMobileComponent } from './components/order-details-mobile/order-details-mobile.component';
import { MainContentMobileComponent } from './components/main-content-mobile/main-content-mobile.component';
import { ExpiredLinkComponent } from './pages/expired-link/expired-link.component';
import { SideComponentComponent } from './components/side-component/side-component.component';
import { OrderStatusComponent } from './components/order-status/order-status.component';
import { CourierContactMobileComponent } from './components/courier-contact-mobile/courier-contact-mobile.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CourierContactComponent } from './components/courier-contact/courier-contact.component';
import { CourierInfoComponent } from './components/courier-info/courier-info.component';
import { CallCourierBtnComponent } from './components/call-courier-btn/call-courier-btn.component';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { OrderItemsComponent } from './components/order-items/order-items.component';
import { MapComponent } from './components/map/map.component';
import { NgxMapboxGLModule } from "ngx-mapbox-gl";
import { OrderProgressMobileComponent } from './components/order-progress-mobile/order-progress-mobile.component';


@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    AppComponent,
    ButtonComponent,
    LoaderComponent,
    CourierContactComponent,
    LoaderComponent,
    LoaderComponent,
    ExpiredLinkComponent,
    HeaderMobileComponent,
    OrderDetailsMobileComponent,
    MainContentMobileComponent,
    SideComponentComponent,
    OrderStatusComponent,
    CourierContactMobileComponent,
    LoaderComponent,
    DeliveryAddressComponent,
    OrderDetailsComponent,
    LoaderComponent,
    ToolbarComponent,
    CourierInfoComponent,
    CallCourierBtnComponent,
    DateFormatPipe,
    OrderItemsComponent,
    MapComponent,
    OrderProgressMobileComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    LayoutModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: "en",
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxMapboxGLModule.withConfig({
      accessToken: environment.mapbox.accessToken
    })
  ],
  exports: [TranslateModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
