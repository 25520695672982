<div class="container">
  <mgl-map
    [style]="'mapbox://styles/mapbox/dark-v9'"
    [zoom]="[11]"
    [center]="[46.772133, 23.5982]"
  >
    <mgl-marker [lngLat]="pickupCoordinates">
      <div class="marker pickup">

      </div>
    </mgl-marker>
    <mgl-marker [lngLat]="dropoffCoordinates">
      <div class="marker dropoff">

      </div>
    </mgl-marker>

    <mgl-marker [lngLat]="courierCoordinates">
      <div class="marker courier">

      </div>
    </mgl-marker>
  </mgl-map>
</div>

