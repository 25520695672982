import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map, Observable } from "rxjs";
import * as moment from 'moment';
import { Order, OrderResponse } from "src/app/models/order";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class OrderService {

  constructor(private httpClient: HttpClient) {
  }

  getOrder(orderId: string, token: string): Observable<Order> {
    return this.httpClient.get<OrderResponse>(
      `${environment.apiUrl}/orders/${orderId}/tracking`,
      {
        headers: new HttpHeaders({
            "api-key": token,
            "app-client": "OrderTracking"
          }
        )
      }
    ).pipe(
      map(OrderService.postprocessOrderResponse)
    );
  }

  private static postprocessOrderResponse(response: OrderResponse): Order {
    const processedOrder: Order = {
      courier:{
        id: response.courier?.id,
        first_name: response.courier?.first_name,
        last_name: response.courier?.last_name,
        image_url: response.courier?.image_url,
        phone_number: response.courier?.phone_number,
        coordinates: response.courier?.coordinates && {
          lat: response.courier?.coordinates.lat,
          lon: response.courier?.coordinates.lon
        },
        polyline: response.courier?.polyline
      },
      status: response.status,
      pickupAddress: {
        street: response.drop_off_street,
        description: response.drop_off_description,
        country: response.drop_off_country,
        county: response.drop_off_county,
        city: response.drop_off_city,
        zipcode: response.drop_off_zip_code
      },
      dropoffAddress: {
        street: response.pickup_street,
        description: response.pickup_description,
        country: response.pickup_country,
        county: response.pickup_county,
        city: response.pickup_city,
        zipcode: response.pickup_zip_code
      },
      dropoffTime: response.drop_off_time && {
        start: moment(response.drop_off_time.start_time),
        end: moment(response.drop_off_time.end_time)
      },
      pickupTime: response.pickup_time && {
        start: moment(response.pickup_time.start_time),
        end: moment(response.pickup_time.end_time)
      },
      pickupEstimatedTime: moment(response.pickup_estimated_time),
      dropoffEstimatedTime: moment(response.drop_off_estimated_time),
      items:[
        {description:"Pizza", quantity: 2},
        {description: "Burger", quantity: 1}
      ],

    };
    if (response.drop_off_lat && response.drop_off_lon) {
      processedOrder.dropoffAddress.coordinates = {
        lat: response.drop_off_lat,
        lon: response.drop_off_lon,
      }
    }

    if (response.pickup_lat && response.pickup_lon) {
      processedOrder.pickupAddress.coordinates = {
        lat: response.pickup_lat,
        lon: response.pickup_lon,
      }
    }
    return processedOrder;
  }
}
