<div *ngIf="order" class="sidebar-container">
  <img class="logo" src="assets/img/Logo.svg" />

  <!--TODO; figure out proper states;-->
  <div [ngSwitch]="order.status">
    <ng-template [ngSwitchCase]="OrderStatus.UNASSIGNED">
      <div class="title">{{'order.statuses.received.title'|translate}}</div>
      <div class="subtitle">{{'order.statuses.received.subtitle'|translate}}</div>
      <img class="illustration" src="assets/img/illustrations/Received.svg" />
    </ng-template>

    <ng-template [ngSwitchCase]="OrderStatus.UPCOMING">
      <div class="title">{{'order.statuses.assigned.title'|translate}}</div>
      <div class="subtitle">{{'order.statuses.assigned.subtitle'|translate}}</div>
      <div *ngIf="order.pickupEstimatedTime" class="estimated">
        <div class="grey-text">
          {{'estimated-pick-up'|translate}}
        </div>
        <div class="time">
          {{ order.pickupEstimatedTime | dateFormat: "HH:mm" }}
        </div>
      </div>
      <img class="illustration" src="assets/img/illustrations/Courier%20assigned.svg" />
      <app-courier-contact [courierDetails]="order.courier"></app-courier-contact>
    </ng-template>

    <ng-template [ngSwitchCase]="OrderStatus.WAITING_FOR_PICKUP">
      <div class="title">{{'order.statuses.pick-up-progress.title'|translate}}</div>
      <div class="subtitle">{{'order.statuses.pick-up-progress.subtitle'|translate}}</div>
      <div *ngIf="order.pickupEstimatedTime" class="estimated">
        <div class="grey-text">
          {{'estimated-pick-up'|translate}}
        </div>
        <div class="time">
          {{ order.pickupEstimatedTime | dateFormat: "HH:mm" }}
        </div>
      </div>
      <img class="illustration" src="assets/img/illustrations/Pick-up%20in%20progress.svg" />
      <app-courier-contact [courierDetails]="order.courier"></app-courier-contact>
    </ng-template>

    <ng-template [ngSwitchCase]="OrderStatus.PICKED_UP">
      <div class="title">{{'order.statuses.picked-up.title'|translate}}</div>
      <div class="subtitle">{{'order.statuses.picked-up.subtitle'|translate}}</div>
      <div *ngIf="order.dropoffEstimatedTime" class="estimated">
        <div class="grey-text">
          {{'estimated-drop-off'|translate}}
        </div>
        <div class="time">
          {{ order.dropoffEstimatedTime | dateFormat: "HH:mm" }}
        </div>
      </div>
      <img class="illustration" src="assets/img/illustrations/Picked%20up.svg" />
      <app-courier-contact [courierDetails]="order.courier"></app-courier-contact>
    </ng-template>

    <ng-template [ngSwitchCase]="OrderStatus.WAITING_FOR_DROP_OFF">
      <div class="title">{{'order.statuses.delivery-progress.title'|translate}}</div>
      <div class="subtitle">{{'order.statuses.delivery-progress.subtitle'|translate}}</div>
      <div *ngIf="order.dropoffEstimatedTime" class="estimated">
        <div class="grey-text">
          {{'estimated-drop-off'|translate}}
        </div>
        <div class="time">
          {{ order.dropoffEstimatedTime | dateFormat: "HH:mm" }}
        </div>
      </div>
      <img class="illustration" src="assets/img/illustrations/Picked%20up.svg" />
    </ng-template>

    <ng-template [ngSwitchCase]="OrderStatus.DROPPED_OFF">
      <div class="title">{{'order.statuses.dropped-off.title'|translate}}</div>
      <div class="subtitle">{{'order.statuses.dropped-off.subtitle'|translate}}</div>
      <img class="illustration" src="assets/img/illustrations/Dropped%20off.svg" />
      <app-button [textButton]="'rate' | translate" [color]="'white'" [borderButton]="true"></app-button>
    </ng-template>
  </div>
</div>
