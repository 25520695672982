<div class="container" [ngClass]="{expanded: expanded}">
  <div class="actions-container">
    <div *ngIf="!expanded; else expandedContainer">
      <app-order-progress-mobile [order]="order"></app-order-progress-mobile>
      <app-button [textButton]="'showDetails' | translate"
                  [color]="'teal'"
                  (click)="showMoreInfoEvent.emit('')">
      </app-button>
      <app-call-courier-btn *ngIf="order.courier && order.courier.phone_number" [phoneNumber]="order.courier!.phone_number"></app-call-courier-btn>
    </div>

    <ng-template #expandedContainer>
      <app-toolbar [orderId]="orderId"></app-toolbar>
      <app-order-status [orderStatus]='order.status'></app-order-status>
      <app-order-items [items]='order.items'></app-order-items>
      <app-delivery-address [address]="order.dropoffAddress"></app-delivery-address>
      <app-courier-contact-mobile *ngIf="order.courier" [courierDetails]='order.courier'></app-courier-contact-mobile>
    </ng-template>
  </div>
</div>
