import { Courier } from "./courier";
import { Moment } from "moment";
import { Coordinates } from "./coordinates";

export interface Timeframe {
  start: Moment;
  end: Moment;
}

// TODO - cleanup, keep only relevant attrs
export interface OrderResponse {
  status: OrderDeliveryStatus;
  ride_id?: number;
  client_ref?: string;
  note?: string;
  vendor_name?: string;

  drop_off_details?: string;

  non_contact_delivery?: boolean;
  drop_off_time_start?: string;
  drop_off_time_end?: string;

  pickup_details?: string;
  pickup_time_start?: string;
  pickup_time_end?: string;
  pickup_street?: string;
  pickup_description?: string;
  pickup_city?: string;
  pickup_county?: string;
  pickup_country?: string;
  pickup_zip_code?: string;
  pickup_lat?: number;
  pickup_lon?: number;
  pickup_contact_name?: string;
  pickup_contact_phone?: string;
  pickup_estimated_time?: string;
  pickup_result?: string;
  pickup_result_date?: string;
  pickup_time?: {
    start_time: string;
    end_time: string;
  };

  drop_off_street?: string;
  drop_off_description?: string;
  drop_off_city?: string;
  drop_off_county?: string;
  drop_off_country?: string;
  drop_off_zip_code?: string;
  drop_off_lat?: number;
  drop_off_lon?: number;
  drop_off_contact_name?: string;
  drop_off_contact_phone?: string;
  drop_off_result?: string;
  drop_off_result_date?: string;
  drop_off_estimated_time?: string;
  drop_off_time?: {
    start_time: string;
    end_time: string;
  };


  recipient_client_ref?: string;
  vendor_client_ref?: string;
  delivery_item_quantities?: number[];
  delivery_items_descriptions?: string[];
  delivery_fail_reason?: string;

  courier?: Courier;
}
export interface Address {
  street?: string,
  description?: string,
  country?: string,
  county?: string,
  city?: string,
  zipcode?: string,
  coordinates?: Coordinates
}

export interface Order {
  courier?: Courier,
  status: string,
  pickupAddress: Address,
  dropoffAddress: Address
  pickupTime?: Timeframe,
  pickupEstimatedTime: Moment,
  dropoffTime?: Timeframe,
  dropoffEstimatedTime: Moment,
  items: Item[],
}

// TODO update when we get response;
export interface Item{
  description: string,
  quantity: number
}

export enum OrderDeliveryStatus {
  UNASSIGNED = 'UNASSIGNED',
  UPCOMING = 'UPCOMING',
  FAILED = 'FAILED',
  WAITING_FOR_PICKUP = 'WAITING_FOR_PICKUP',
  PICKED_UP = 'PICKED_UP',
  WAITING_FOR_DROP_OFF = 'WAITING_FOR_DROP_OFF',
  DROPPED_OFF = 'DROPPED_OFF',
  CANCEL_ORDER = 'CANCEL_ORDER'
}
