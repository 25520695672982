<div class="order-status-container">
  <div class="container-title">{{'order.order-status'|translate}}</div>
  <div class="status-wrapper" *ngFor="let status of mappedOrderStatuses">
    <div class="dots-wrapper">
      <div class="circle" [class.active]="status.id <= activeStatusIndex"></div>
      <div class="small-dots-wrapper" [class.hide]="status.id === mappedOrderStatuses.length -1"
           [class.active]="activeStatusIndex && status.id<activeStatusIndex">
        <div class="circle small"></div>
        <div class="circle small"></div>
        <div class="circle small"></div>
      </div>
    </div>
    <div class="info-container">
      <div class="title">{{status.title|translate}}</div>
      <div class="subtitle">{{status.subtitle|translate}}</div>
    </div>
  </div>
</div>
